import React from 'react';

function EuiNav(props: {
  className?: string;
  children: React.ReactNode;
}): JSX.Element {
  let className = 'ui-nav';
  if (props.className) className += ` ${props.className}`;

  return <div className={className}>{props.children}</div>;
}

export default EuiNav;
